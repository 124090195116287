import BigNumber from 'bignumber.js'
import React, { useEffect, useCallback, useState } from 'react'
import { Clock } from 'react-feather'
import web3NoAccount from 'utils/web3'
import web3 from 'web3'
import useSousHarvest from '../../../hooks/useSousHarvest'
import UnstakingFeeCountdownRow from './UnstakingFeeCountdownRow'
import useSousUnstake from '../../../hooks/useSousUnstake'
import { useCurrentBlock } from '../../../store/hooks'
import WithdrawIDOModal from './/WithdrawIDOModal'
import { ScheduleOutlined } from '@ant-design/icons'
// import { Accordion } from 'react-bootstrap';
import { Collapse, Modal } from 'antd'
import 'antd/dist/antd.css'
import DepositModal from './DepositModal'
import { fetchPoolsUserDataAsync } from '../../../store/pools/index'
import useSousStake from '../../../hooks/useSousStake'
import Value from '../../../components/Value'
import { getBalanceNumber } from '../../../utils/formatBalance'
import BlockCountdown from './BlockCountdown'
import BlockCountdownDetail from './BlockCountdownDetail'
import BlockCountdownUnstake from './BlockCountdownUnstake'
import WalletProviderModal from '../../../components/WalletProviderModal/WalletProviderModal'
import useModal from '../../../hooks/useModal'
import Button from '../../../components/ButtonV2'
import { useDispatch } from 'react-redux'
import { useWallet } from 'use-wallet'
import useSousApprove from '../../../hooks/useSousApprove'
import { getPoolBlockUnstake } from '../helpers'
import styled from 'styled-components'
import { ChevronDown, ChevronUp } from 'react-feather'
import { formatNumber } from '../../../utils/formatBalance'
import CulatingImages from './images/calculating.png'
import InfoImages from './images/info-2.png'
import InfoImages2 from './images/image-2.png'
import ApyCalculatorModal from 'components/ApyCalculatorModal'
import { BIG_ZERO } from '../../../utils/bigNumber'
import sousChefABI from '../../../config/abi/sousChef.json'
import { getContract } from '../../../utils/contractHelpers'

const PoolCardList = ({ pool, userDataLoaded }) => {
	const [isView, setIsView] = useState(false)
	const { account } = useWallet()
	const dispatch = useDispatch()
	const [requestedApproval, setRequestedApproval] = useState(false)
	const { userData } = pool
	const { onStake } = useSousStake(pool.sousId)
	const { onUnstake } = useSousUnstake(pool.sousId)
	const { onReward } = useSousHarvest(pool.sousId)
	const [pendingTx, setPendingTx] = useState(false)
	const [listSchedule, setListSchedule] = useState([])

	const stakings = userData?.stakedBalance
		? new BigNumber(userData.stakedBalance)
		: BIG_ZERO
	// These will be reassigned later if its Auto CAKE vault
	let stakingTokenBalance = getBalanceNumber(
		stakings,
		pool.stakingToken.decimals,
	)
	let stakingTokenDollarBalance = getBalanceNumber(
		stakings.multipliedBy(pool.stakingTokenPrice),
		pool.stakingToken.decimals,
	)

	const earnings = userData?.pendingReward
		? new BigNumber(userData.pendingReward)
		: BIG_ZERO
	let earningTokenDollarBalance = getBalanceNumber(
		earnings.multipliedBy(pool.earningTokenPrice),
		pool.earningToken.decimals,
	)

	const [onPresentWalletProviderModal] = useModal(
		// @ts-ignore
		<WalletProviderModal />,
		'provider',
	)

	const allowance = userData.allowance
	const { onApprove } = useSousApprove(
		pool.stakingToken.address,
		pool.contractAddress,
	)
	const isApproved = account && allowance && allowance.isGreaterThan(0)

	const handleApprove = useCallback(async () => {
		try {
			setRequestedApproval(true)
			await onApprove()
			dispatch(fetchPoolsUserDataAsync(account))
			setRequestedApproval(false)
		} catch (e) {
			console.log(e)
			setRequestedApproval(false)
		}
	}, [onApprove, dispatch, account, pool])

	const handleStake = async (amount) => {
		await onStake(amount, pool.stakingToken.decimals)
		dispatch(fetchPoolsUserDataAsync(account))
	}

	const handleUnstake = async (amount) => {
		await onUnstake(amount, pool.stakingToken.decimals)
		dispatch(fetchPoolsUserDataAsync(account))
	}
	const handleMigrate = async (amount) => {
		await onUnstake(amount, pool.stakingToken.decimals)
		dispatch(fetchPoolsUserDataAsync(account))
	}

	const handleReward = useCallback(async () => {
		try {
			setPendingTx(true)
			await onReward()
			dispatch(fetchPoolsUserDataAsync(account))
			setPendingTx(false)
		} catch (e) {
			setPendingTx(false)
			console.error(e)
		}
	}, [account, dispatch, onReward, pool])

	const [onPresentStakeInPoolModal] = useModal(
		<DepositModal
			stakingToken={pool.stakingToken}
			max={userData.stakingTokenBalance}
			onConfirm={handleStake}
		/>,
	)

	const [onPresentWithdrawModal] = useModal(
		<WithdrawIDOModal
			fees={pool.fees}
			lastStakingBlock={userData.lastStakingBlock}
			blockPeriod={pool.blockPeriod}
			stakingToken={pool.stakingToken}
			max={userData.unstakeAmount}
			balance={userData.stakedBalance}
			onConfirm={handleUnstake}
		/>,
	)

	const [onPresentApyModal] = useModal(
		<ApyCalculatorModal
			tokenPrice={pool.earningTokenPrice}
			apr={pool.apr}
			earningTokenSymbol={pool.earningToken.symbol}
			performanceFee={pool.fees}
		/>,
	)
	const { Panel } = Collapse

	function callback(key) {
		console.log(key)
	}
	const [isModalVisible, setIsModalVisible] = useState(false)

	const showModal = () => {
		setIsModalVisible(true)
	}

	const handleOk = () => {
		setIsModalVisible(false)
	}

	const handleCancel = () => {
		setIsModalVisible(false)
	}
	const [isCollapseVisible, setIsCollapseVisible] = useState(true)
	const lastStakingBlockCustom = userData.lastStakingBlock
	const blockPeriodCustom = pool.blockPeriod
	const currentBlock = useCurrentBlock()
	const hasHarvest = lastStakingBlockCustom + blockPeriodCustom > currentBlock
	const disHarvestTimer = account && lastStakingBlockCustom && hasHarvest
	const { hasPoolStarted } = getPoolBlockUnstake(pool, currentBlock)
	// console.log('pool',pool)
	const contractAddressPool = getContract(sousChefABI, pool.contractAddress)
	useEffect(() => {
		if (account && contractAddressPool) {
			contractAddressPool.methods
				.getUserStakedCount(account)
				.call()
				.then(async (resStakedCount) => {
					if (resStakedCount !== '0') {
						let arrData = []
						// get list api
						for (let i = 0; i < parseInt(resStakedCount); i++) {
							arrData.push(
								contractAddressPool.methods.stakeDetails(account, i).call(),
							)
						}
						let listData = []
						// get result
						await Promise.all([arrData]).then((resAll) => {
							for (let i = 0; i < parseInt(resStakedCount); i++) {
								resAll[0][i].then((data) => {
									listData.push(data)
								})
							}
						})
						setTimeout(() => {
							setListSchedule(listData)
						}, 1000)
					}
				})
		}
	}, [account])
	const contentModalClick = (
		<>
			<table className="table-modal">
				<tr>
					<th>Amount</th>
					<th>Stake block</th>
					<th>Remaining unlock</th>
				</tr>
				{listSchedule
					.filter((x) => x.amount > 0)
					.map((item, i) => {
						return (
							<tr key={`schedule_${i}`}>
								<td>{item.amount / 1e18}</td>
								<td>{item.startStakeBlock}</td>
								<td>
									{item.endStakeBlock}{' '}
									<a
										href={`https://bscscan.com/block/countdown/${item.endStakeBlock}`}
										target="_blank"
										rel="noreferrer"
										className="text-white"
									>
										<Clock className="ml-1" size={16} />
									</a>
								</td>
							</tr>
						)
					})}
			</table>
		</>
	)

	const contentHeader = (
		<div className="box-header-list">
			<div className="item-tow-part d-flex">
				<div className="box-logo">
					<img
						src={`/tokens/${pool.earningToken.symbol.toLowerCase()}.svg?v=1`}
						alt=""
						className="logo-big"
					/>
					{pool.cssLP ? (
						<img
							src={`/tokens/${pool.stakingToken.symbol.toLowerCase()}.svg?v=1`}
							alt=""
							className="logo-small cssLP"
						/>
					) : (
						<img
							src={`/tokens/${pool.stakingToken.symbol.toLowerCase()}.svg?v=1`}
							alt=""
							className="logo-small"
						/>
					)}
				</div>
			</div>
			<div className="item-tow-part d-flex">
				<div className="box-text-logo">
					<p className="text-big">
						{!pool.cssLP ? (
							<>
								{pool.stakingToken.symbol} - {pool.earningToken.symbol}
							</>
						) : (
							<>{pool.stakingToken.symbol}</>
						)}
					</p>

					{pool.lockup ? (
						<p className="text-small">
							Lock-up {pool.lockup} {pool.lockup > 1 ? 'days' : 'day'}
						</p>
					) : (
						''
					)}
				</div>
			</div>
			<div className="item-tow-part dis-none-500">
				<div className="box-earned">
					<p className="text-earned">Earned</p>
					<p className="total-coin">
						<span>
							<Value
								size={20}
								value={
									account
										? getBalanceNumber(
												userData.pendingReward,
												pool.earningToken.decimals,
										  )
										: 0
								}
							/>
						</span>
						<span className="color-main-fff">{pool.earningToken.symbol}</span>
					</p>
					{/* <p className="total-coin balance-exchange">
						<span>
							{userData.stakedBalance > 0 ? (
								<>
									<Value size={14} unit="~" value={earningTokenDollarBalance} />
								</>
							) : (
								// '0'
								<Value size={14} value={0} />
							)}
						</span>
						<span className="color-main-fff">USD</span>
					</p> */}
				</div>
			</div>
			<div className="item-tow-part">
				<div className="box-earned">
					<p className="text-earned">APR</p>
					<p className="total-coin">
						<span>{pool.apr ? <>{formatNumber(pool.apr)}%</> : '0%'}</span>
					</p>
				</div>
			</div>
			<div className="item-tow-part dis-none">
				<div className="box-total-staked">
					{pool.typePool !== 1 ? (
						<p className="text-total-staked">Total Liquidity</p>
					) : (
						<p className="text-total-staked">Total staked</p>
					)}

					<p className="number-total-staked d-flex">
						<Value
							size={15}
							unit="$"
							value={pool.stakedTvl ? +pool.stakedTvl : 0}
						/>
					</p>
				</div>
			</div>
			<div className="item-tow-part dis-none">
				<div className="box-apr">
					<p className="text-apr">Lock-up term</p>
					<p className="number-apr">
						{pool.lockup ? (
							<>
								<p className="text-small">
									{pool.lockup} {pool.lockup > 1 ? 'days' : 'day'}
								</p>
							</>
						) : (
							<p className="text-small">---</p>
						)}
					</p>
				</div>
			</div>

			<div className="item-tow-part dis-none dis-none-901">
				<div className="box-ends-in">
					<p className="number-ends-in d-flex">
						<BlockCountdown pool={pool} />
						<span className="box-schedule">
							<button
								type="button"
								className="btn-schedule"
								onClick={showModal}
							>
								<ScheduleOutlined />
							</button>
						</span>
					</p>
				</div>
			</div>
			<div className="item-tow-part dis-none">
				<Button
					link
					click={() => setIsView(!isView)}
					className="height-80"
					right={
						isView ? (
							<ChevronUp color="#FFD60A" size={16} />
						) : (
							<ChevronDown color="#FFD60A" size={16} />
						)
					}
				/>
			</div>
			<Modal
				className="modal-schedule"
				title="Schedule"
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				{contentModalClick}
			</Modal>
		</div>
	)
	const contentBody = (
		<div className="box-content-body">
			<div className="item-three-part">
				<p className="line-total-staked dis-mobile">
					<span className="text-left">Total staked:</span>
					<span className="text-right">
						<Value
							size={15}
							unit="$"
							value={pool.stakedTvl ? +pool.stakedTvl : 0}
						/>
					</span>
				</p>
				<p className="text-view-info dis-mobile hide-mobile-cus">
					{pool.isFinished === true ? (
						<UnstakingFeeCountdownRow
							fees={0}
							lastStakingBlock={userData.lastStakingBlock}
							blockPeriod={pool.blockPeriod}
						/>
					) : (
						<UnstakingFeeCountdownRow
							fees={pool.fees}
							lastStakingBlock={userData.lastStakingBlock}
							blockPeriod={pool.blockPeriod}
						/>
					)}
				</p>
				<div className="d-flex justify-content-between flex-wrap align-items-center">
					<p className="mb-0 text-ends-in dis-mobile">
						<BlockCountdownDetail pool={pool} />
					</p>
					<p className="mb-0 text-schedules dis-mobile">
						Schedules
						<button type="button" className="btn-schedule pf-5" onClick={showModal}>
							<ScheduleOutlined />
						</button>
					</p>
				</div>

				<p className="text-view-info dis-mobile hide-mobile-cus">
					{pool.isFinished === true ? (
						<UnstakingFeeCountdownRow
							fees={0}
							lastStakingBlock={userData.lastStakingBlock}
							blockPeriod={pool.blockPeriod}
						/>
					) : (
						<UnstakingFeeCountdownRow
							fees={pool.fees}
							lastStakingBlock={userData.lastStakingBlock}
							blockPeriod={pool.blockPeriod}
						/>
					)}
				</p>
				<p className="text-view-info">
					<a href={`${pool.stakingToken.projectLink}`}>
						See Token Info <img src={InfoImages} alt="" />
					</a>
				</p>
				{pool.typePool !== 1 ? (
					<>
						<p className="text-view-info">
							<a
								href={`https://bscscan.com/address/${pool.contractAddress.toLowerCase()}`}
								target="_blank"
								rel="noreferrer"
							>
								View pool <img src={InfoImages} alt="" />
							</a>
						</p>
						<p className="text-view-info">
							<a
								href={`https://bscscan.com/address/${pool.stakingToken.address.toLowerCase()}`}
								target="_blank"
								rel="noreferrer"
							>
								View Contract <img src={InfoImages} alt="" />
							</a>
						</p>
					</>
				) : (
					<p className="text-view-info">
						<a
							href={`https://bscscan.com/address/${pool.contractAddress.toLowerCase()}`}
							target="_blank"
							rel="noreferrer"
						>
							View Contract <img src={InfoImages} alt="" />
						</a>
					</p>
				)}
			</div>
			<div className="item-four-part">
				<div className="box-harvest">
					<div className="harvest-left">
						<p className="text-harvest">Earned</p>
						<p className="total-coin">
							<span className="row-flex">
								<Value
									size={20}
									value={
										account
											? getBalanceNumber(
													userData.pendingReward,
													pool.earningToken.decimals,
											  )
											: 0
									}
								/>
								<span className="mgl-5 text-usd-cus card-details">
									<span className="mgl-5">{pool.earningToken.symbol}</span>
								</span>
							</span>
						</p>
						<p className="total-coin">
							<span className="row-flex">
								{userData.stakedBalance > 0 ? (
									<>
										<Value
											size={14}
											className="text-white"
											unit="~"
											value={earningTokenDollarBalance}
										/>
									</>
								) : (
									<Value size={14} value={0} />
								)}
								<span className="mgl-5 text-usd-cus card-details">
									<span className="mgl-5 text-balance-exchange">USD</span>
								</span>
							</span>
						</p>
					</div>
					<div className="harvest-right">
						<Button
							ghost
							disabled={
								!account ||
								pendingTx ||
								userData.pendingReward.eq(new BigNumber(0)) ||
								!isApproved ||
								pool.disHarvest === true ||
								(disHarvestTimer && pool.countdownt === true)
							}
							loading={pendingTx}
							text="Harvest"
							click={handleReward}
						/>
					</div>
				</div>
			</div>
			<div className="item-four-part">
				<div className="box-start-staking">
					<p className="text-start-staking">Start staking</p>
					<Value
						size={20}
						value={
							account
								? getBalanceNumber(
										userData.stakedBalance,
										pool.stakingToken.decimals,
								  )
								: 0
						}
					/>
					<div className="d-flex mar-t-5">
						<Button
							className="mr-2"
							ghost
							disabled={
								!account ||
								userData.stakedBalance.eq(new BigNumber(0)) ||
								!isApproved ||
								pool.disUnstake === true ||
								hasPoolStarted
							}
							text="Unstake"
							click={onPresentWithdrawModal}
						/>
						<Button
							disabled={
								!account ||
								!isApproved ||
								pool.isFinished === true ||
								pool.disStake === true
							}
							text="+"
							click={onPresentStakeInPoolModal}
							primary
						/>
					</div>

					{account ? (
						<>
							{userDataLoaded ? (
								<>
									{!isApproved && (
										<Button
											loading={requestedApproval}
											className="w-full btn-cnt mar-t-10"
											text="Approve Contract"
											click={handleApprove}
											primary
										/>
									)}
								</>
							) : (
								<Button className="w-full" text="Loading..." />
							)}
						</>
					) : (
						<Button
							className="w-full btn-cnt mar-t-10"
							text="Connect Wallet"
							click={onPresentWalletProviderModal}
							primary
						/>
					)}
				</div>
			</div>
		</div>
	)
	return (
		<>
			{pool.iodPartnesr ? (
				<div className="box-list-item ido-pool">
					<Collapse visible={isCollapseVisible} onChange={callback}>
						<Panel header={contentHeader} key="1">
							{contentBody}
						</Panel>
					</Collapse>
				</div>
			) : (
				<div className="box-list-item ">
					<Collapse visible={isCollapseVisible} onChange={callback}>
						<Panel header={contentHeader} key="1">
							{contentBody}
						</Panel>
					</Collapse>
				</div>
			)}
		</>
	)
}
export default PoolCardList
