import React, { memo, useCallback, useMemo, useState } from 'react'
import { useWallet } from 'use-wallet'
import {BrowserRouter as Router, Switch, Route, Link, useLocation} from "react-router-dom";
import Button from '../ButtonV2'
import HeaderMobile from "./HeaderMobile";
import { isMobile } from "react-device-detect";
import { ReactComponent as CloseIcon } from '../../assets/images/menu/close.svg'
import { ReactComponent as Logo } from '../../assets/images/menu/logo-bscs.svg'
import { ReactComponent as LogoGrey } from '../../assets/images/logo.svg'
import { ReactComponent as OpenIcon } from '../../assets/images/menu/open.svg'
import WalletProviderModal from '../WalletProviderModal/WalletProviderModal'
import AccountModal from '../../components/TopBar/components/AccountModal'
import useModal from '../../hooks/useModal'
import { Menu, Modal } from "antd";
import './index.less'

const Header = () => {
	// const [isOpen, setIsOpen] = useState<boolean>(init)
	const { account } = useWallet()
	const customAccount = useMemo<string>(() => {
		if (account) {
			return `${account.substr(0, 4)}...${account.substr(account.length - 4)}`
		} else {
			return 'Connect Wallet'
		}
	}, [account])
	const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)
	// const handleBack = useCallback(async () => {
	// 	if (onBack) {
	// 		setIsOpen(await onBack())
	// 	}
	// }, [onBack])
  	const [onPresentAccountModal] = useModal(<AccountModal />)
  
	if (isMobile) {
		return (
			<HeaderMobile />
		)
	}

	return (
		<div className="bsc-header">
			<div className="bsc-header-left">
				<Menu
					mode="horizontal"
					style={{
						background:
						"transparent",
					}}>
					<Menu.Item key="logo" className="logoMenu">
						<Link to="/">
							<img className='logoImg' src="../images/logo.svg" alt="logo" />
						</Link>
					</Menu.Item>

					<Menu.Item key="home">
						<a href="https://kingdomstarter.io/">Home</a>
					</Menu.Item>

					<Menu.Item key="launchpad">
						<a href="https://kingdomstarter.io/launchpad">LaunchPad</a>
					</Menu.Item>

					<Menu.Item key="staking" className="ant-menu-item-selected">
						<Link to="/">Staking</Link>
					</Menu.Item>

					<Menu.Item key="gamehub">
						<a href="https://kingdomstarter.io/gamehub">GameHub</a>
					</Menu.Item>

					<Menu.Item key="airdrop" className="btn-airdrop">
						<a href="https://kingdomstarter.io/airdrop">Airdrop</a>
					</Menu.Item>

					<Menu.Item className="buykdg" key="buyKDG">
						<a onClick={() => window.open('https://kinglive.finance/swap', '_blank')}>Buy KDG</a>
					</Menu.Item>
				</Menu>
			</div>
			<div className="bsc-header-right cus-btn-cnt">
				{!account ? (
					<Button
						primary
						click={onPresentWalletProviderModal}
						text={customAccount}
					/>
				) : (
					<Button
						primary
						click={onPresentAccountModal}
						text={customAccount}
					/>
				)}
			</div>
		</div>
	)
}

export default Header
