import React, { useState } from 'react'
import 'antd/dist/antd.css';
import './footer.css'
import { Layout, Row, Col, } from 'antd';
import { Link } from 'react-router-dom';
const { Header, Content, Footer } = Layout;

function Footers() {

  return (
    <> 
        <div className="c-footerHome">
            <div className="c-footerHome__link">
                <div className="c-footerHome__row w-2">
                    <h1 className="c-sidebar__logo footer">
                        <a href={'/'}> 
                          <img src='../images/logo.svg' alt='logo' /> 
                        </a> 
                    </h1> 
                    <span className='c-footerHome_sologun'>Future of decentralized <br/> & crowdfunding platform</span>
                <div className='footer-icon' >
                  <a className='iconMedia1' href="https://t.me/kdg_ann">
                    <img src='../images/tele.png' alt='logo' />
                  </a>
                  <a className='iconMedia' href="https://twitter.com/Kingdomstarter">
                    <img src='../images/tw.png' alt='logo' />
                  </a>
                  <a className='iconMedia' href="https://www.youtube.com/channel/UCl7ezf4kJUxjlPJwaoPtapA">
                    <img src='../images/youtobe.png' alt='logo' />
                  </a>
                  <a className='iconMedia' href="https://www.facebook.com/KingdomStarter">
                    <img src='../images/fb.png' alt='logo' />
                  </a>
                  <a className='iconMedia' href="https://kingdomstarter.medium.com/">
                    <img src='../images/Vector.png' alt='logo' />
                  </a> 
                </div>
                </div>
                <div className="c-footerHome__row">
                    <p>Product</p>
                    <ul>
                        <li><a href="https://kingdomstarter.io/launchpad/completed" target="_blank" rel="noopener noreferrer">IDO Launchpad</a></li>
                        <li><a href="https://kingdomstarter.io/ino/ended" target="_blank" rel="noopener noreferrer">INO Launchpad</a></li>
                        <li><a href="https://kingdomstarter.io/airdrop/completed" target="_blank" rel="noopener noreferrer">Airdrop Portal</a></li>
                        <li><a href="https://kingdomstarter.io/gamehub" target="_blank" rel="noopener noreferrer">Gamehub</a></li>
                    </ul>
                </div>
                <div className="c-footerHome__row">
                    <p>Resources</p>
                    <ul>
                        <li><a href="https://kingdomstarter.medium.com/" target="_blank" rel="noopener noreferrer">Blog</a></li>
                        <li><a rel="noopener noreferrer">FAQ</a></li>
                        <li><a href="https://docs.kingdomstarter.io/" target="_blank" rel="noopener noreferrer">Documents</a></li>
                    </ul>
                </div>
                <div className="c-footerHome__row">
                    <p>Social</p>
                    <ul>
                        <li><a href="https://twitter.com/Kingdomstarter" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                        <li><a href="https://t.me/kdg_ann" target="_blank" rel="noopener noreferrer">Telegram</a></li>
                        <li><a href="https://www.facebook.com/KingdomStarter" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                        <li><a href="https://www.youtube.com/channel/UCl7ezf4kJUxjlPJwaoPtapA" target="_blank" rel="noopener noreferrer">Youtube</a></li>
                    </ul>
                </div>
                 <div className="c-footerHome__row">
                    <p>Legal</p>
                    <ul>
                        <li><a href="https://kingdomstarter.io/terms" >Terms</a></li> 
                        <li><a href="https://kingdomstarter.io/privacy" >Privacy</a></li> 
                        <li><a href="https://kingdomstarter.io/cookies" >Cookies</a></li>  
                    </ul>
                </div>
            </div> 
        </div>
      <div className='footer-coppy'>
        <p className='footer-coppy-text'>Copyright © 2023 Kingdomstarter.io All Rights Reserved</p>
      </div>
    </>
  );
}

export default Footers;
