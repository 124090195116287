import React from 'react'
import { Clock, Target } from 'react-feather'
import Value from '../../../components/Value'
import { useCurrentBlock } from '../../../store/hooks'
import { getPoolBlockInfo } from '../helpers'
import Countdown from 'react-countdown'

const BlockCountdownUnstake = ({ pool }) => {
	const currentBlock = useCurrentBlock()

	const {
		shouldShowBlockCountdown,
		blocksUntilStart,
		blocksRemaining,
		hasPoolStarted,
		blocksToDisplay,
	} = getPoolBlockInfo(pool, currentBlock)

	var timeS = blocksToDisplay * 3
	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return
		} else {
			return (
				<span>
					{days}d : {hours} : {minutes} : {seconds}
				</span>
			)
		}
	}
	return (
		<>
			{pool.startBlock > currentBlock ? (
				<>
					<span className="text-cus-name">Pool start in</span>
					<div className="d-flex align-items-center text-cus-re">
						<Value size={15} value={blocksToDisplay} decimals={0} />
						<span className="ml-1">Blocks</span>
					</div>
				</>
			) : (
				<>
					<span className="text-cus-name">Remaining</span>
					
				</>
			)}
		</>
	)
}

export default BlockCountdownUnstake
