import React from 'react'
import { Clock, Target } from 'react-feather'
import Value from '../../../components/Value'
import { useCurrentBlock } from '../../../store/hooks'
import { getPoolBlockInfo } from '../helpers'
import Countdown from 'react-countdown'
import BigNumber from 'bignumber.js'

const BlockCountdown = ({ pool }) => {
	const currentBlock = useCurrentBlock()

	const {
		shouldShowBlockCountdown,
		blocksUntilStart,
		blocksRemaining,
		hasPoolStarted,
		blocksToDisplay,
	} = getPoolBlockInfo(pool, currentBlock)
	var timeS = blocksToDisplay * 3
	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return
		} else {
			return (
				<span>
					{days}d : {hours} : {minutes} : {seconds}
				</span>
			)
		}
	}
	return (
		<>
			{pool.startBlock > currentBlock ? (
				<>
					<div className="text-white d-flex justify-content-between flex-wrap align-items-center">
						<p className="mb-0">Pool start in</p>
						<div className="d-flex align-items-center">
							<Value size={15} value={blocksToDisplay} decimals={0} />
							<span className="ml-1">Blocks</span>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="text-white justify-content-between flex-wrap align-items-center">
						<p className="mb-0">Remaining</p>
						<div className="d-flex align-items-center">
						Schedules
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default BlockCountdown
