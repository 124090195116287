import React, { memo, useCallback, useMemo, useState } from 'react'
import { useWallet } from 'use-wallet'
import {BrowserRouter as Router, Switch, Route, Link, useLocation} from "react-router-dom";
import Button from '../ButtonV2'
import {
    MenuUnfoldOutlined,
  } from '@ant-design/icons';

import WalletProviderModal from '../WalletProviderModal/WalletProviderModal'
import AccountModal from '../../components/TopBar/components/AccountModal'
import useModal from '../../hooks/useModal'
import { Menu, Modal } from "antd";
import { HeaderBSCProps } from './index.d'
import './index.less'

const HeaderMobile = () => {
	// const [isOpen, setIsOpen] = useState<boolean>(init)
	const { account } = useWallet()
	const customAccount = useMemo(() => {
		if (account) {
			return `${account.substr(0, 4)}...${account.substr(account.length - 4)}`
		} else {
			return 'Connect Wallet'
		}
	}, [account])
	const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)
	// const handleBack = useCallback(async () => {
	// 	if (onBack) {
	// 		setIsOpen(await onBack())
	// 	}
	// }, [onBack])
  const [onPresentAccountModal] = useModal(<AccountModal />)
  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };


	return (
		<div className="bsc-header">
			<div className="bsc-header-left cus">
            <div className="header-mobile">
            <div className="header-left">
                <div className="box-img">
                  <Link  to="/">
                    <img src='../images/logo.svg' alt='logo' />
                  </Link>
                </div>
            </div>
            <div className="header-right">
                <button className="btn-active" onClick={handleToggle}>
                    <MenuUnfoldOutlined />
                </button>
            </div>
        </div>

            <div className={isActive ? "menu-mobile hide-menu" : "menu-mobile show-menu"}>
            <Menu
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            theme="dark"
            >
                <Menu.Item key="home" >
                  <a href="https://kingdomstarter.io/">Home</a>
                </Menu.Item>
                
                <Menu.Item key="launchpad" >
                  <a href="https://kingdomstarter.io/launchpad">LaunchPad</a>
                </Menu.Item>

                <Menu.Item key="staking" >
                <Link to="/staking">Staking</Link>
                </Menu.Item>

							<Menu.Item key="staking">
								<a href="https://kingdomstarter.io/gamehub">GameHub</a>
							</Menu.Item>

              <Menu.Item key="airdrop" className="btn-airdrop">
                <a href="https://kingdomstarter.io/airdrop">Airdrop</a>
              </Menu.Item>

                <Menu.Item key="buyKDG" >
                <a onClick={() => window.open('https://kinglive.finance/swap', '_blank')}>Buy KDG</a>
                </Menu.Item>
                <Menu.Item key="connect" >
                    <div className="btn-mobile">
                      {!account ? (
                          <Button
                              primary
                              click={onPresentWalletProviderModal}
                              text={customAccount}
                          />
                      ) : (
                          <Button
                              primary
                              click={onPresentAccountModal}
                              text={customAccount}
                          />
                      )}
                    </div>
                </Menu.Item>

                <div className="bottom-menu-mobile">
                <div className="social-mobile">
                  <ul className="list-social">
                    <li>
                      <img src='../images/tele.png' alt='logo' />
                    </li>
                    <li>
                      <img src='../images/tw.png' alt='logo' />
                    </li>
                    <li>
                      <img src='../images/youtobe.png' alt='logo' />
                    </li>
                    <li>
                      <img src='../images/fb.png' alt='logo' />
                    </li>
                    <li>
                      <img src='../images/Vector.png' alt='logo' />
                    </li>
                  </ul>
                  <div className="copy-right">
                    Copyright © 2020 KingdomGame.Org All Rights Reserved
                  </div>
                </div>
            </div>
            </Menu>
        </div>

			</div>
		</div>
	)
}

export default HeaderMobile
