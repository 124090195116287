import React, { useCallback , useState, useEffect } from 'react'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useWallet } from 'use-wallet'
import useTokenBalance from '../../../hooks/useTokenBalance'
import useBao from '../../../hooks/useBao'
import { getBaoAddress } from '../../../bao/utils'
import { getBalanceNumber } from '../../../utils/formatBalance'
import Button from '../../Button'
import CardIcon from '../../CardIcon'
import Label from '../../Label'
import Modal, { ModalProps } from '../../Modal'
import ModalContent from '../../ModalContent'
import ModalTitle from '../../ModalTitle'
import Value from '../../Value'
import './style.less'
import { useHookHeader } from "./Store";

const AccountModal: React.FC<ModalProps> = ({ onDismiss }) => {
	const [state, actions] = useHookHeader();
	console.log("staking:", state)
	const { account, reset } = useWallet()
	const notify = () => toast("Copy Successful !");
	const handleSignOutClick = useCallback(() => {
		onDismiss!()
		reset()
	}, [onDismiss, reset])

	const bao = useBao()
	const baoBalance = useTokenBalance(getBaoAddress(bao))
	const accountEllipsis = account
    ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}`
    	: "Connect Wallet";

  	const accountEllipsisWl = account
		? `${account.substring(0, 10)}...${account.substring(account.length - 10)}`
		: "Connect Wallet";
		
	useEffect(() => {
		if(account){
		  actions.getBalanceByAddress(account);
		}
	  }, [account]);

	console.log(state.myBalance)
	
	return (
		<Modal>
			<div className="title-header-md">
				<ModalTitle text="Wallet" />
			</div>
			<ModalContent>
				<div style={{ display: 'flex' }}>
					<StyledBalanceWrapper>
						<CardIcon>
							<img src="/logo2.png" height={50} />
						</CardIcon>
						<div className="value-kdg">
							<StyledBalance>
								<Value value={getBalanceNumber(baoBalance)} />
								<div className="label-value">
									<Label text="KDG Balance" />
								</div>
							</StyledBalance>
						</div>
					</StyledBalanceWrapper>
				</div>
				{state.myBalance && (
					<>
						<div className="box-detail-modal">
							<div className="item-wl">
								<div className="text-l">
									Wallet Address:
								</div>
								<div className="text-r">
									<span className="text-right">
									{accountEllipsisWl}
									<button onClick={notify} className="btn-toast">
										<CopyToClipboard
										text={account}
										onCopy={() =>
											<img src='../images/copy.png' alt='logo' />
										}
										>
										<span className="btn-copy"><img src='../images/copy.png' alt='logo' /></span>
										</CopyToClipboard>
									</button>

									<ToastContainer />
								</span>
								</div>
							</div>
							<div className="item-wl">
								<div className="text-l">
									Staking:
								</div>
								<div className="text-r color-main-wl">
									{state.myBalance.staking} KDG
								</div>
							</div>
							<div className="item-wl">
								<div className="text-l">
									Tier:
								</div>
								<div className="text-r">
									{state.myBalance.tierName}
								</div>
							</div>
						</div>
						<div className="wl-bottom">
							<ul className="list-tier">
								<li>
									<div className={state.myBalance.staking >= 100000 ? 'box-img active' : 'box-img'}>
										<img src='../images/active-1.png' alt='logo' />
									</div>
									<div className="text-tier">
									<h3 className="title-tier">
										Star
									</h3>
									<span>100,000 KDG</span>
									</div>
								</li>
								<li>
									<div className={state.myBalance.staking >= 200000 ? 'box-img active' : 'box-img'}>
									<img src='../images/active-2.png' alt='logo' />
									</div>
									<div className="text-tier">
									<h3 className="title-tier">
										Mercury
									</h3>
									<span>200,000 KDG</span>
									</div>
								</li>
								<li>
									<div className={state.myBalance.staking >= 400000 ? 'box-img active' : 'box-img'}>
									<img src='../images/active-3.png' alt='logo' />
									</div>
									<div className="text-tier">
									<h3 className="title-tier">
										Venus
									</h3>
									<span>400,000 KDG</span>
									</div>
								</li>
								<li>
									<div className={state.myBalance.staking >= 800000 ? 'box-img active' : 'box-img'}>
									<img src='../images/active-4.png' alt='logo' />
									</div>
									<div className="text-tier">
									<h3 className="title-tier">
										Earth
									</h3>
									<span>800,000 KDG</span>
									</div>
								</li>
								<li>
									<div className={state.myBalance.staking >= 1500000 ? 'box-img active' : 'box-img'}>
									<img src='../images/active-5.png' alt='logo' />
									</div>
									<div className="text-tier">
									<h3 className="title-tier">
										Mars
									</h3>
									<span>1,500,000 KDG</span>
									</div>
								</li>
							</ul>
						</div>
					</>
				)}
				<div className="wl-footer">
					<h3 className="title-footer">
					You don’t have any KDG token
					</h3>
					<a onClick={() => window.open('https://kinglive.finance/swap', '_blank')} className="swap-now">
					Swap Now
					</a>
				</div>
				<div className="btn-wl">
					<Button
						href={`https://bscscan.com/address/${account}`}
						text="View on Bscscan"
					/>
					<Button onClick={handleSignOutClick} text="Sign out" />
				</div>
			</ModalContent>
		</Modal>
	)
}

const StyledBalance = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	color:#9F9F9F;
`

const Text = styled.div`
	font-size: 20px;
	font-weight: bold;
`

const StyledBalanceWrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	flex: 1;
	margin-bottom: ${(props) => props.theme.spacing[4]}px;
`

export default AccountModal
