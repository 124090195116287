/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from 'axios';
import { createStore, createHook, createContainer, createSubscriber } from 'react-sweet-state';

const StoreHeader = createStore({
  initialState: {
    myBalance: {
      tierName: "",
      staking: 0
    }
  },
  actions: {
    getBalanceByAddress: (address) => async ({ setState }) => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/staking-balances/tier/${address}`)
        
        if(res && res.status === 200) {
          setState({ myBalance: res.data || {} })
          // console.log(res.data)
          // console.log(res.data.address)
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
  name: 'Header Store',
});

export const useHookHeader = createHook(StoreHeader);
export const Container = createContainer(StoreHeader, {
  onInit: () => ({ setState }, props) => {
    setState({ ...props })
  },
});
export const Subscriber = createSubscriber(StoreHeader);
