import tokens from './tokens'

const poolsConfig = [
	// typePool = 1 --> start pool
	// typePool = 2 --> farm pool
	// typePool = 3 --> IDO pool

	// typeLP = 1 --> BUSD LP
	// typeLP = 2 --> BNB LP
	// cssLP = true --> lp
	
	// {
	// 	sousId: 0,
	// 	stakingToken: tokens.kingdomgame,
	// 	earningToken: tokens.kingdomgame,
	// 	contractAddress: '0xD5E3E807e05D6920F5087f271B6d304f093026bd',
	// 	fees: 0,
	// 	blockPeriod: 86400,
	// 	startBlock: 12781020,
	// 	endBlock: 999243332,
	// 	tokenPerBlock: 636000000000000,
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	Inactive: false,
	// 	disUnstake: false,
	// 	disStake: false,
	// 	disHarvest: false,
	// 	typePool: 1,
	// 	lockup: 1000,
	// },
	// {
	// 	sousId: 3,
	// 	stakingToken: tokens.kingdomgame,
	// 	earningToken: tokens.kingdomgame,
	// 	contractAddress: '0x5dBa3b19B38fD8aD467437B9891e1F93874492d7',
	// 	fees: 0,
	// 	blockPeriod: 86400,
	// 	startBlock: 12781020,
	// 	endBlock: 999243332,
	// 	tokenPerBlock: 434000000000000000,
	// 	isFinished: false,
	// 	stakingLimit: 0,
	// 	Inactive: false,
	// 	disUnstake: false,
	// 	disStake: false,
	// 	disHarvest: false,
	// 	typePool: 1,
	// 	lockup: 1
	// },




	{
		sousId: 1,
		stakingToken: tokens.kingdomgame,
		earningToken: tokens.kingdomgame,
		contractAddress: '0x60f38A74a7Da4bd921202Ba1903D5A1C8DEda6Fa',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 12781020,
		endBlock: 999243332,
		tokenPerBlock: 1000000000000000000,
		isFinished: false,
		stakingLimit: 0,
		Inactive: false,
		disUnstake: false,
		disStake: false,
		disHarvest: false,
		typePool: 1,
		lockup: 60,
	},
	{
		sousId: 2,
		stakingToken: tokens.kingdomgame,
		earningToken: tokens.kingdomgame,
		contractAddress: '0xdf785AB1Bbaa32dc6CAcf44AF49eb6C87b17505C',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 12781020,
		endBlock: 999243332,
		tokenPerBlock: 400000000000000000,
		isFinished: false,
		stakingLimit: 0,
		Inactive: false,
		disUnstake: false,
		disStake: false,
		disHarvest: false,
		typePool: 1,
		lockup: 30
	},
	{
		sousId: 3,
		stakingToken: tokens.kingdomgame,
		earningToken: tokens.kingdomgame,
		contractAddress: '0x5dBa3b19B38fD8aD467437B9891e1F93874492d7',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 12781020,
		endBlock: 999243332,
		tokenPerBlock: 100000000000000000,
		isFinished: false,
		stakingLimit: 0,
		Inactive: false,
		disUnstake: false,
		disStake: false,
		disHarvest: false,
		typePool: 1,
		lockup: 1
	},
	{
		sousId: 4,
		stakingToken: tokens.kdgbusdcakelp,
		earningToken: tokens.kingdomgame,
		contractAddress: '0xcD493a8f0eEAAdaFbd87F52f28D8c2a2d745ece9',
		fees: 0,
		blockPeriod: 86400,
		startBlock: 12781020,
		endBlock: 999243332,
		tokenPerBlock: 500000000000000000,
		isFinished: true,
		stakingLimit: 0,
		Inactive: false,
		disUnstake: false,
		disStake: false,
		disHarvest: false,
		typePool: 2,
		cssLP: true,
		lockup: 45
	},
]
// contract test 0x1818233Dcb4C0919cF256De06C007DE2D2563111
export default poolsConfig
