import React from 'react'
import PoolCards from './components/PoolCards'
import PageHeader from '../../components/PageHeader/PageHeader'
import Page from '../../components/Page/Page'
import FarmCards from '../../views/Farms/components/FarmCards'

const Pools = () => {
	return (
		<Page background="/images/bg-top.png">
			{/* <PageHeader
				title="Start Pools"
				subtitle1="Stake token to earn. Rewards are calculated per block."
				subtitle2="High return, low risk."
			/> */}
			<PoolCards />
		</Page>
	)
}

export default Pools
