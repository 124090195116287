import React from 'react'
import styled from 'styled-components'

interface CardIconProps {
	children?: React.ReactNode
}

const CardIcon: React.FC<CardIconProps> = ({ children }) => (
	<StyledCardIcon>{children}</StyledCardIcon>
)

const StyledCardIcon = styled.div`
	font-size: 36px;
	height: 60px;
	width: 60px;
	border-radius: 40px;
	align-items: center;
	display: flex;
	justify-content: center;
	img {
		object-fit: cover;
		height: 60px;
		width: 60px;
	}
`

export default CardIcon
