import BigNumber from 'bignumber.js'
import React, { useEffect, useCallback, useState } from 'react'
import { Clock } from 'react-feather'
import useSousHarvest from '../../../hooks/useSousHarvest'
import UnstakingFeeCountdownRow from './UnstakingFeeCountdownRow'
import useSousUnstake from '../../../hooks/useSousUnstake'
import { useCurrentBlock } from '../../../store/hooks'
import WithdrawIDOModal from './/WithdrawIDOModal'
import { ScheduleOutlined } from '@ant-design/icons'
import DepositModal from './DepositModal'
import { Collapse, Modal } from 'antd'
import { fetchPoolsUserDataAsync } from '../../../store/pools/index'
import useSousStake from '../../../hooks/useSousStake'
import Value from '../../../components/Value'
import { getBalanceNumber } from '../../../utils/formatBalance'
import BlockCountdown from './BlockCountdown'
import BlockCountdownUnstakeGrid from './BlockCountdownUnstakeGrid'
import WalletProviderModal from '../../../components/WalletProviderModal/WalletProviderModal'
import useModal from '../../../hooks/useModal'
import Button from '../../../components/ButtonV2'
import { useDispatch } from 'react-redux'
import { useWallet } from 'use-wallet'
import useSousApprove from '../../../hooks/useSousApprove'
import { getPoolBlockUnstake } from '../helpers'
import styled from 'styled-components'
import { ChevronDown, ChevronUp } from 'react-feather'
import { formatNumber } from '../../../utils/formatBalance'
import InfoImages from './images/info-2.png'
import InfoImages2 from './images/image-2.png'
import { BIG_ZERO } from '../../../utils/bigNumber'
import ApyCalculatorModal from 'components/ApyCalculatorModal'
import CulatingImages from './images/calculating.png'
import sousChefABI from '../../../config/abi/sousChef.json'
import { getContract } from '../../../utils/contractHelpers'

const FCard = styled.div`
	width: 100%;
	max-width: 320px;
	border-radius: 4px;
	background: #1b1b1c;
	height: fit-content;
	box-shadow: rgb(0 0 0 / 6%) 0px 16px 24px, rgb(0 0 0 / 4%) 0px 2px 6px,
		rgb(0 0 0 / 4%) 0px 0px 1px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	position: relative;
	text-align: center;
`
const PoolCard = ({ pool, userDataLoaded }) => {
	const [isView, setIsView] = useState(false)
	const { account } = useWallet()
	const dispatch = useDispatch()
	const [requestedApproval, setRequestedApproval] = useState(false)
	const { userData } = pool
	const { onStake } = useSousStake(pool.sousId)
	const { onUnstake } = useSousUnstake(pool.sousId)
	const { onReward } = useSousHarvest(pool.sousId)
	const [pendingTx, setPendingTx] = useState(false)
	const [listSchedule, setListSchedule] = useState([])

	const stakings = userData?.stakedBalance
		? new BigNumber(userData.stakedBalance)
		: BIG_ZERO
	// These will be reassigned later if its Auto CAKE vault
	let stakingTokenBalance = getBalanceNumber(
		stakings,
		pool.stakingToken.decimals,
	)
	let stakingTokenDollarBalance = getBalanceNumber(
		stakings.multipliedBy(pool.stakingTokenPrice),
		pool.stakingToken.decimals,
	)
	const earnings = userData?.pendingReward
		? new BigNumber(userData.pendingReward)
		: BIG_ZERO
	let earningTokenDollarBalance = getBalanceNumber(
		earnings.multipliedBy(pool.earningTokenPrice),
		pool.earningToken.decimals,
	)
	const [onPresentWalletProviderModal] = useModal(
		// @ts-ignore
		<WalletProviderModal />,
		'provider',
	)

	const allowance = userData.allowance
	const { onApprove } = useSousApprove(
		pool.stakingToken.address,
		pool.contractAddress,
	)
	const isApproved = account && allowance && allowance.isGreaterThan(0)

	const handleApprove = useCallback(async () => {
		try {
			setRequestedApproval(true)
			await onApprove()
			dispatch(fetchPoolsUserDataAsync(account))
			setRequestedApproval(false)
		} catch (e) {
			console.log(e)
			setRequestedApproval(false)
		}
	}, [onApprove, dispatch, account, pool])

	const handleStake = async (amount) => {
		await onStake(amount, pool.stakingToken.decimals)
		dispatch(fetchPoolsUserDataAsync(account))
	}

	const handleUnstake = async (amount) => {
		await onUnstake(amount, pool.stakingToken.decimals)
		dispatch(fetchPoolsUserDataAsync(account))
	}

	const handleReward = useCallback(async () => {
		try {
			setPendingTx(true)
			await onReward()
			dispatch(fetchPoolsUserDataAsync(account))
			setPendingTx(false)
		} catch (e) {
			setPendingTx(false)
			console.error(e)
		}
	}, [account, dispatch, onReward, pool])

	const [onPresentStakeInPoolModal] = useModal(
		<DepositModal
			stakingToken={pool.stakingToken}
			max={userData.stakingTokenBalance}
			onConfirm={handleStake}
		/>,
	)

	const [onPresentWithdrawModal] = useModal(
		<WithdrawIDOModal
			fees={pool.fees}
			lastStakingBlock={userData.lastStakingBlock}
			blockPeriod={pool.blockPeriod}
			stakingToken={pool.stakingToken}
			max={userData.unstakeAmount}
			balance={userData.stakedBalance}
			onConfirm={handleUnstake}
		/>,
	)
	const [onPresentApyModal] = useModal(
		<ApyCalculatorModal
			tokenPrice={pool.earningTokenPrice}
			apr={pool.apr}
			earningTokenSymbol={pool.earningToken.symbol}
			performanceFee={pool.fees}
		/>,
	)
	const OpenLinkIcon = () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21.545"
			height="21.526"
			viewBox="0 0 21.545 21.526"
		>
			<g
				id="Group_1924"
				data-name="Group 1924"
				transform="translate(-1995.521 -3234.797)"
			>
				<path
					id="Path_3145"
					data-name="Path 3145"
					d="M2006.277,3256.316c-2.229,0-4.459.013-6.689,0a4.275,4.275,0,0,1-3.9-3.1,4.619,4.619,0,0,1-.16-1.092q-.015-6.625,0-13.25a4.253,4.253,0,0,1,3.115-3.908,4.944,4.944,0,0,1,1.117-.158c1.8-.016,3.6,0,5.4-.01a1.034,1.034,0,0,1,1.02.645,1.054,1.054,0,0,1-.947,1.49c-1.767.01-3.534,0-5.3.005a2.129,2.129,0,0,0-2.121,1.407,2.552,2.552,0,0,0-.151.862q-.014,6.36,0,12.72a2.167,2.167,0,0,0,2.263,2.258q6.361.006,12.721,0a2.171,2.171,0,0,0,2.263-2.258c.006-1.758,0-3.516,0-5.274a1.065,1.065,0,0,1,2.128-.093c.009,1.952.067,3.906-.019,5.854a4.175,4.175,0,0,1-3.258,3.785,5.424,5.424,0,0,1-1.148.119c-2.112.011-4.224.005-6.335.005Z"
					fill="#0dba88"
				/>
				<path
					id="Path_3146"
					data-name="Path 3146"
					d="M2195.075,3236.963h-.339c-1.186,0-2.372,0-3.558,0a1.047,1.047,0,0,1-1.021-.9,1.061,1.061,0,0,1,.776-1.2,1.391,1.391,0,0,1,.35-.032q3.167,0,6.334,0a1.033,1.033,0,0,1,1.13,1.1q.007,3.23,0,6.459a1.067,1.067,0,0,1-2.1.256,1.817,1.817,0,0,1-.035-.45q0-1.691,0-3.381v-.3c-.1.085-.157.133-.212.188q-3.75,3.743-7.5,7.489a1.227,1.227,0,0,1-.937.466,1.028,1.028,0,0,1-.952-.634.964.964,0,0,1,.151-1.106c.3-.338.63-.654.951-.975q3.37-3.375,6.742-6.747C2194.915,3237.135,2194.971,3237.073,2195.075,3236.963Z"
					transform="translate(-181.703 -0.027)"
					fill="#0dba88"
				/>
			</g>
		</svg>
	)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const showModal = () => {
		setIsModalVisible(true)
	}

	const handleOk = () => {
		setIsModalVisible(false)
	}

	const handleCancel = () => {
		setIsModalVisible(false)
	}
	const lastStakingBlockCustom = userData.lastStakingBlock
	const blockPeriodCustom = pool.blockPeriod
	const currentBlock = useCurrentBlock()
	const hasHarvest = lastStakingBlockCustom + blockPeriodCustom > currentBlock
	const disHarvestTimer = account && lastStakingBlockCustom && hasHarvest
	const { hasPoolStarted } = getPoolBlockUnstake(pool, currentBlock)
	const contractAddressPool = getContract(sousChefABI, pool.contractAddress)
	useEffect(() => {
		if (account && contractAddressPool) {
			contractAddressPool.methods
				.getUserStakedCount(account)
				.call()
				.then(async (resStakedCount) => {
					if (resStakedCount !== '0') {
						let arrData = []
						// get list api
						for (let i = 0; i < parseInt(resStakedCount); i++) {
							arrData.push(
								contractAddressPool.methods.stakeDetails(account, i).call(),
							)
						}
						let listData = []
						// get result
						await Promise.all([arrData]).then((resAll) => {
							for (let i = 0; i < parseInt(resStakedCount); i++) {
								resAll[0][i].then((data) => {
									listData.push(data)
								})
							}
						})
						setTimeout(() => {
							setListSchedule(listData)
						}, 1000)
					}
				})
		}
	}, [account])
	const contentModalClick = (
		<>
			<table className="table-modal">
				<tr>
					<th>Amount</th>
					<th>Stake block</th>
					<th>Remaining unlock</th>
				</tr>
				{listSchedule
					.filter((x) => x.amount > 0)
					.map((item, i) => {
						return (
							<tr key={`schedule_${i}`}>
								<td>{item.amount / 1e18}</td>
								<td>{item.startStakeBlock}</td>
								<td>
									{item.endStakeBlock}{' '}
									<a
										href={`https://bscscan.com/block/countdown/${item.endStakeBlock}`}
										target="_blank"
										rel="noreferrer"
										className="text-white"
									>
										<Clock className="ml-1" size={16} />
									</a>
								</td>
							</tr>
						)
					})}
			</table>
		</>
	)
	return (
		<FCard>
			<div className="bsc-farm-item-header">
				<div className="bsc-farm-item-header-center">
					<span>
						{!pool.cssLP ? (
							<>
								{pool.stakingToken.symbol} - {pool.earningToken.symbol}
							</>
						) : (
							<>{pool.stakingToken.symbol}</>
						)}
						{pool.lockup ? (
							<>
								<p className="text-small">
									{pool.lockup} {pool.lockup > 1 ? 'days' : 'day'}
								</p>
							</>
						) : (
							''
						)}
					</span>
				</div>
				<div className="position-relative mr-3">
					<img
						style={{
							width: 65,
							height: 65,
							background: 'white',
							borderRadius: '50%',
						}}
						src={`/tokens/${pool.earningToken.symbol.toLowerCase()}.svg?v=1`}
					/>
					{pool.cssLP ? (
						<>
							<img
								className="position-absolute"
								style={{
									width: 50,
									right: -15,
									bottom: 0,
								}}
								src={`/tokens/${pool.stakingToken.symbol.toLowerCase()}.svg?v=1`}
							/>
						</>
					) : (
						<>
							<img
								className="position-absolute"
								style={{
									width: 34,
									height: 34,
									right: -10,
									bottom: 0,
									borderRadius: '50%',
									background: 'white',
								}}
								src={`/tokens/${pool.stakingToken.symbol.toLowerCase()}.svg?v=1`}
							/>
						</>
					)}
				</div>
			</div>
			<div style={{ padding: 15, borderBottom: '1px solid #2E3145' }}>
				<div className="text-left mt-2 text-md">
					<div className="d-flex justify-content-between align-items-center">
						<span className="text-cus-name">APR</span>
						<span className="text-cus-re">
							{pool.apr ? <>{formatNumber(pool.apr)}%</> : '0%'}
						</span>
					</div>
					<p className="mb-0">
						<span className="text-earned">Earned</span>
					</p>
					<div className="d-flex justify-content-between align-items-center">
						<span className="row-flex row-flex-cus">
							<Value
								size={20}
								value={
									account
										? getBalanceNumber(
												userData.pendingReward,
												pool.earningToken.decimals,
										  )
										: 0
								}
							/>
							<div className="mgl-5 card-details">
								<span className="mgl-5">{pool.earningToken.symbol}</span>
							</div>
						</span>
						<Button
							ghost
							disabled={
								!account ||
								pendingTx ||
								userData.pendingReward.eq(new BigNumber(0)) ||
								!isApproved ||
								pool.disHarvest === true ||
								(disHarvestTimer && pool.countdownt === true)
							}
							loading={pendingTx}
							text="Harvest"
							click={handleReward}
						/>
					</div>
				</div>
				<div className="text-left mt-2 text-md">
					<p className="mb-0">
						<span className="text-earned">Staked</span>
					</p>
					<div className="d-flex justify-content-between align-items-center row-flex">
						<span className="row-flex row-flex-cus">
							<Value
								size={20}
								value={
									account
										? getBalanceNumber(
												userData.stakedBalance,
												pool.stakingToken.decimals,
										  )
										: 0
								}
							/>
							<div className="mgl-5 card-details">
								<span className="mgl-5">{pool.stakingToken.symbol}</span>
							</div>
						</span>
						<div className="d-flex">
							<Button
								className="mr-2"
								ghost
								disabled={
									!account ||
									userData.stakedBalance.eq(new BigNumber(0)) ||
									!isApproved ||
									pool.disUnstake === true ||
									hasPoolStarted
								}
								text="-"
								click={onPresentWithdrawModal}
							/>

							<Button
								disabled={
									!account ||
									!isApproved ||
									pool.isFinished === true ||
									pool.disStake === true
								}
								text="+"
								click={onPresentStakeInPoolModal}
								primary
							/>
						</div>
					</div>
				</div>
				<div></div>
			</div>
			{isView && (
				<div className="bsc-farm-item-more">
					<div className="d-flex justify-content-between align-items-center">
						<span className="text-cus-name">Total staked</span>
						<Value
							size={16}
							unit="$"
							value={pool.stakedTvl ? +pool.stakedTvl : 0}
						/>
					</div>
					<div className="d-flex justify-content-between align-items-center">
						<span className="text-cus-name">Lock-up term</span>
						{pool.lockup ? (
							<span className="text-cus-re">
								{pool.lockup} {pool.lockup > 1 ? 'days' : 'day'}
							</span>
						) : (
							<span className="text-cus-re">---</span>
						)}
					</div>
					<div className="d-flex justify-content-between align-items-center">
						<BlockCountdownUnstakeGrid pool={pool} />
						<span className="text-cus-name">
							<p className="mb-0 text-schedules ">
								Schedules
								<button
									type="button"
									className="btn-schedule pf-5"
									onClick={showModal}
								>
									<ScheduleOutlined />
								</button>
							</p>
						</span>
					</div>
					<Modal
						className="modal-schedule"
						title="Schedule"
						visible={isModalVisible}
						onOk={handleOk}
						onCancel={handleCancel}
					>
						{contentModalClick}
					</Modal>
					<div className="box-view-contract">
						<Button
							className="bsc-farm-item-more-bottom pd-top"
							text="See Token Info"
							link
							click={() => {
								window.open(
									`${pool.stakingToken.projectLink}`,
									'_blank', // <- This is what makes it open in a new window.
								)
							}}
							right={<img src={InfoImages} alt="" />}
						/>

						{pool.typePool !== 1 ? (
							<>
								<Button
									className="bsc-farm-item-more-bottom pd-top"
									text="View Contract"
									link
									click={() => {
										window.open(
											`https://bscscan.com/address/${pool.stakingToken.address.toLowerCase()}`,
											'_blank', // <- This is what makes it open in a new window.
										)
									}}
									right={<img src={InfoImages} alt="" />}
								/>
								<Button
									className="bsc-farm-item-more-bottom pd-top"
									text="View pool"
									link
									click={() => {
										window.open(
											`https://bscscan.com/address/${pool.contractAddress.toLowerCase()}`,
											'_blank', // <- This is what makes it open in a new window.
										)
									}}
									right={<img src={InfoImages} alt="" />}
								/>
							</>
						) : (
							<Button
								className="bsc-farm-item-more-bottom pd-top"
								text="View Contract"
								link
								click={() => {
									window.open(
										`https://bscscan.com/address/${pool.contractAddress.toLowerCase()}`,
										'_blank', // <- This is what makes it open in a new window.
									)
								}}
								right={<img src={InfoImages} alt="" />}
							/>
						)}
					</div>
				</div>
			)}

			{account ? (
				<>
					{userDataLoaded ? (
						<>
							{!isApproved && (
								<Button
									loading={requestedApproval}
									className="w-full mar-0-15 btn-cnt my-2"
									text="Approve Contract"
									click={handleApprove}
									primary
								/>
							)}
						</>
					) : (
						<Button className="w-full my-2" text="Loading..." />
					)}
				</>
			) : (
				<Button
					className="w-full mar-0-15 btn-cnt my-2"
					text="Connect Wallet"
					click={onPresentWalletProviderModal}
					primary
				/>
			)}

			<div className="bsc-farm-item-footer">
				<Button
					link
					click={() => setIsView(!isView)}
					text={isView ? 'Hide' : 'Details'}
					right={
						isView ? (
							<ChevronUp color="#FFD60A" size={16} />
						) : (
							<ChevronDown color="#FFD60A" size={16} />
						)
					}
				/>
			</div>
		</FCard>
	)
}

export default PoolCard
