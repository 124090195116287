const tokens = {
	// add pool stake 
	bscs: {
		symbol: 'BSCS',
		address: '0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d',
		decimals: 18,
		projectLink: 'https://bscstation.finance/',
	},
	kingdomgame: {
		symbol: 'KDG',
		address: '0x87a2d9a9a6b2d61b2a57798f1b4b2ddd19458fb6',
		decimals: 18,
		projectLink: 'https://kingdomgame.org/',
	},
	ido: {
		symbol: 'IDO',
		address: '0x7df2a33bfcd76240b796b0fbbed406aa1fcce830',
		decimals: 18,
		projectLink: 'https://bscstation.finance/',
	},
	// add pool farm

	kdglp: {
		symbol: 'KDG-BNB LP',
		address: '0xc3d3bee26a10a6ce9b7baaf4d953a05db194c2c6',
		decimals: 18,
		projectLink: 'https://kingdomgame.org/',
	},
	kdgbusdcakelp: {
		symbol: 'KDG-BUSD CAKE LP',
		address: '0x557f1a5ccd2d72190b31d50d5524f756620a799e',
		decimals: 18,
		projectLink: 'https://kingdomgame.org/',
	},
	
}

export default tokens
