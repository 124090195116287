import React from 'react'
import styled from 'styled-components'

import { ReactComponent as XIcon } from '../../assets/images/x-modal.svg'
import Button from '../ButtonV2'

interface ModalTitleProps {
	text?: string
	onDismiss?: any
}

const ModalTitle: React.FC<ModalTitleProps> = ({ text, onDismiss }) => (
	<StyledModalTitle>
		<span>{text}</span>
		<Button
			click={onDismiss}
			// left={<XIcon />}
			link
		/>
	</StyledModalTitle>
)

const StyledModalTitle = styled.div`
	align-items: center;
	color: rgb(255, 244, 227);
	display: flex;
	font-size: 20px;
	border-bottom: 1px solid #2D2D30;
	font-weight: 700;
	height: 120px;
	justify-content: space-between;
	padding: 12px 24px;
`

export default ModalTitle
